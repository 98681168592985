import i18next from 'i18next';

const header = async () => {
  const envValues = {
    OvgPay: `${process.env.OVG_PAY_URL}?lang=${i18next.language}`,
    Starbridge: `${process.env.STAR_BRIDGE_URL}?lang=${i18next.language}`,
    Exchanger: `${process.env.EXCHANGER_URL}?lang=${i18next.language}`,
    Staking: `${process.env.STAKING_URL}home?lang=${i18next.language}`,
    WalletRegistration: `${process.env.WALLET_URL}auth/user-registration?lang=${i18next.language}`,
    Wallet: `${process.env.WALLET_URL}auth/login/mnemonic-phrase?lang=${i18next.language}`,
    Promocodes: `${process.env.PROMOCODES_URL}auth/login/mnemonic-phrase/
    ?lang=${i18next.language}`,
    BigDipper: `${process.env.BIG_DIPPER_URL}?lang=${i18next.language}`,
    Polls: `${process.env.POLLS_URL}?lang=${i18next.language}`,
    Plugin: process.env.EXTANTION_SHOP_URL,
    AppStore: process.env.APP_STORE_URL,
    GoolgePlay: process.env.GOOGLE_PLAY_URL,
    Telegram:
      i18next.language === 'ru'
        ? process.env.TELEGRAM_URL
        : process.env.TELEGRAM_URL_EN,
    Github: process.env.GITHUB_URL,
    Twitter: process.env.TWITTER_URL,
  };

  const serviceElements = document.querySelectorAll('[data-service]');
  const burgerModal = document.querySelector('.burger_modal');
  serviceElements.forEach(service => {
    const dataService = service.getAttribute('data-service');
    service.href = envValues[dataService];
  });

  document
    .getElementById('js-votingBtn')
    .addEventListener('click', function () {
      document
        .querySelector('#voting_block_start')
        .scrollIntoView({ behavior: 'smooth' });
    });

  document
    .getElementById('js-faqBtnBurger')
    .addEventListener('click', function () {
      burgerModal.classList.remove('active_burger');
    });

  document
    .getElementById('js-votingBtnBurger')
    .addEventListener('click', function () {
      document
        .querySelector('#voting_block_start')
        .scrollIntoView({ behavior: 'smooth' });
      if (!burgerModal) {
        return;
      }
      burgerModal.classList.remove('active_burger');
    });

  document
    .getElementById('js-servicesBtn')
    .addEventListener('click', function () {
      document
        .querySelector('#services_start')
        .scrollIntoView({ behavior: 'smooth' });
      if (!burgerModal) {
        return;
      }
      burgerModal.classList.remove('active_burger');
    });
};
document.addEventListener('DOMContentLoaded', async () => {
  await header();
});

i18next.on('languageChanged', async lng => {
  await header();
});
